// Filename - Pages/index.js
import '../Style/Main.css';
import '../Style/Work.css';
import React from "react";
import { Helmet } from 'react-helmet';

import { NavLink } from "../Components/HeaderElements";
import CTA from "../Components/CTA.js";

import BrieHagarty from '../Images/home/brie-hagarty.png';
import Scrungee from '../Images/home/the-scrungee.png';
import Daniela from '../Images/home/daniela-bosco.png';
import ASH from '../Images/work/anne-sophie/anne-sophie-1.jpg';

import BGC1 from '../Images/work/bad-girls/bad-girls-collective-6.jpg';
import BGC2 from '../Images/work/bad-girls/bad-girls-collective-2.jpg';
import BGC3 from '../Images/work/bad-girls/bad-girls-collective-3.jpg';
import BGC4 from '../Images/work/bad-girls/bad-girls-collective-8.jpg';

import Lorangerie from '../Images/home/lorangerie.png';
import Jonny from '../Images/home/jonny.png';
import CS from '../Images/home/conceived-space.png';
import JR from '../Images/home/jo-rodrigues.png';
import Livie from '../Images/home/livie-jewelry.png';
import Tulips from '../Images/home/tulipomania.png';
import Lettering from '../Images/home/lettering.png';

const Work = () => {
  return (
    <main id='main' className="wrapper recent-work-page">
      <Helmet>
        <title>Janel Lucia Design Studio</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="atf">
        <h1>A collection of work, <br className='mobile'></br>crafted with care <br className='desktop'></br><span className='h-italics'>& guided by intention.</span></h1>
        {/* <h1>Creating meaningful brands through intentional <span className='h-italics'>creativity & attention to detail.</span></h1> */}
      </section>
      <section className='section section-1'>
        <div className='project project-landscape'>
          <div className='image'>
            <NavLink to="/recent-work/brie-hagarty">
              <h2 >High Vibe Hair Artist</h2>
              <div className='pr-image'>
                <img src={BrieHagarty} alt="Brie Hagarty High Vibe Hair Artist Branding visual" />
              </div>
              <p>— Brand Design, Logo, Squarespace Website, Brand Collateral</p>
            </NavLink>
          </div>
        </div>
      </section>

      <section className='section section-2'>
        <div className='project project-1 project-two-across'>
          <div className='image'>
            <NavLink to="/recent-work/the-scrungee" >
              <h2 >The Scrungee</h2>
              <div className='pr-image'>
                <img src={Scrungee} alt="The Scrungee branding visual" />
              </div>
              <p>— Brand Identity, Logo, Illustration, Packaging </p>
            </NavLink>
          </div>
        </div>
        <div className='project project-2 project-two-across'>
          <div className='image'>
            <NavLink to="/recent-work/daniela-bosco">
              <h2 >Daniela Bosco</h2>
              <div className='pr-image'>
                <img src={Daniela} alt="Daniela Bosco website visual" />
              </div>
              <p>— Brand Design, Logo, Squaresace Website</p>
            </NavLink>
          </div>
        </div>
      </section>

      <section className='section section-3'>
        <div className='project project-landscape'>
          <div className='image'>
            <NavLink to="/recent-work/anne-sophie-hout">
              <h2 >Anne Sophie Hout</h2>
              <div className='pr-image'>
                <img src={ASH} alt="Anne Sophie Hout Photography Website visual" />
              </div>
              <p>— Brand Design, Logo, Squarespace Website</p>
            </NavLink>
          </div>
        </div>
      </section>

      <section className='section section-4 project-four-across'>
        <div className='project'>
          <div className='image'>
            <NavLink to="/recent-work/bad-girls-collective" >
              <h2 >Bad Girls Collective</h2>
              <div className='pr-image'>
                <img src={BGC1} alt="Bad Girls Collective website visual" />
                <img src={BGC2} alt="Bad Girls Collective website visual" />
                <img src={BGC3} alt="Bad Girls Collective website visual" />
                <img src={BGC4} alt="Bad Girls Collective website visual" />
              </div>
              <p>— Shopify Website</p>
            </NavLink>
          </div>
        </div>
      </section>

      <section className='section section-5'>
        <div className='project project-landscape'>
          <div className='image'>
            <NavLink to="/recent-work/lorangerie-photographie" >
              <h2 >L'Orangerie Photographie</h2>
              <div className='pr-image'>
                <img src={Lorangerie} alt="L'Orangerie Photographie branding visual" />
              </div>
              <p>— Brand Design, Logo, Squarespace Website, Brand Collateral</p>
            </NavLink>
          </div>
        </div>
      </section>

      <section className='section section-6'>
        <div className='project project-1 project-two-across'>
          <div className='image'>
            <NavLink to="/recent-work/jonny-silver">
              <h2 >Jonny Silver</h2>
              <div className='pr-image'>
                <img src={Jonny} alt="Jonny Silver website visual" />
              </div>
              <p>— Web Design, Custom Website, Squarespace Website</p>
            </NavLink>
          </div>
        </div>
        <div className='project project-2 project-two-across'>
          <div className='image'>
            <NavLink to="/recent-work/conceived-space">
              <h2 >Conceived Space</h2>
              <div className='pr-image'>
                <img src={CS} alt="Conceived Space editorial visual" />
              </div>
              <p>— Brand Design, Logo, Printed Assets, Wordpress Website</p>
            </NavLink>
          </div>
        </div>
      </section>

      <section className='section section-7'>
        <div className='project project-landscape'>
          <div className='image'>
            <NavLink to="/recent-work/jo-rodrigues" >
              <h2 >Jo Rodrigues</h2>
              <div className='pr-image'>
                <img src={JR} alt="Jo Rodrigues branding visual" />
              </div>
              <p>— Brand Identity, Logo, Wordpress Website, Brand Collateral</p>
            </NavLink>
          </div>
        </div>
      </section>

      <section className='section section-8'>
        <div className='project project-1 project-two-across'>
          <div className='image'>
            <NavLink to="/recent-work/tulipomania">
              <h2 >Tulipomania</h2>
              <div className='pr-image'>
                <img src={Tulips} alt="Tulip illustration visual" />
              </div>
              <p>— Illustration</p>
            </NavLink>
          </div>
        </div>
        <div className='project project-2 project-two-across'>
          <div className='image'>
            <NavLink to="/recent-work/livie-jewelry">
              <h2 >Livie Jewelry</h2>
              <div className='pr-image'>
                <img src={Livie} alt="Livie Jewelry branding visual" />
              </div>
              <p>— Brand Design, Logo, Shopify Website</p>
            </NavLink>
          </div>
        </div>
      </section>

      <section className='section section-9'>
        <div className='project project-landscape'>
          <div className='image'>
            <NavLink to="/recent-work/lettering-archive" >
              <h2 >Lettering Archive</h2>
              <div className='pr-image'>
                <img src={Lettering} alt="Lettering Poster visual" />
              </div>
              <p>— Graphic Design</p>
            </NavLink>
          </div>
        </div>
      </section>
      <div className='divider'></div>
      <CTA />

    </main >
  );
};

export default Work;