// Filename - Pages/index.js
import '../Style/Main.css';
import '../Style/Work.css';
import React from "react";
import { Helmet } from 'react-helmet';
import 'react-slideshow-image/dist/styles.css'

import Marquee from "react-fast-marquee";
import RecentWork from "../Components/RecentWork.js";
import CTA from "../Components/CTA.js";
import { NavLink } from "../Components/HeaderElements";

import Janel1 from '../Images/jlds/janel-lucia-1.jpg';
import Janel2 from '../Images/jlds/janel-lucia-home.jpg';
import Janel3 from '../Images/jlds/janel-lucia-6.jpg';
import Janel4 from '../Images/jlds/janel-lucia-5.jpg';
import Paralax from '../Images/jlds/janel-lucia-bg.jpg';


const Home = () => {
  return (
    <main className='home-page' id='main'>
      <Helmet>
        <title>Janel Lucia Design Studio</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="atf home-atf">
        <div className="wrapper">
          <h1>Bringing visions to <br className='mobile'></br>life <br className='desktop'></br>through <span className='desktop'>thoughtfully curated</span> <span className='h-italics'>creative strategy & design.</span> </h1>
        </div>
      </section>

      <Marquee>
        <p>Logo Design / Custom Logotype</p> • <p>Branding / Visual Identity</p> • <p>Website Design / Build</p> • <p>Editorial Design / Books / Magazines</p> • <p>Packaging Design</p> • <p>Cards / Invites</p> •
      </Marquee>

      <div className='wrapper'>
        <RecentWork />
      </div>

      <section className='meet-janel'>
        <div className='wrapper'>
          <div className='left'>
            <img src={Janel1} alt="Stay Creative and Curious" />
            <img src={Janel2} alt="Stay Creative and Curious" className='home-about-footer-text' />
          </div>
          <div className='right'>
            <h2>Let’s create something <br></br>beautiful together</h2>
            <p><strong>I'm here to spark your curiosity, inspire your creativity and help you find clarity and confidence in your brand.</strong></p>
            <p>So you can focus on your passion and create a heart-driven business you are proud of.</p>
            <p>In a crowded industry, it’s essential that your brand truly reflects who you are. I’m here to help you create a brand you love and a business with the foundation to grow.</p>
            <NavLink to="/about" className="cta" activeStyle>About Janel</NavLink>
            <NavLink to="/contact" className="cta" activeStyle>Work together</NavLink>
          </div>
        </div>
      </section>

      <section className='paralax home-services' style={{ backgroundImage: `url(${Paralax})` }}>
        <div className='home-service-wrap'>
          <div className='each-service'>
            <h3>Logo / Custom Logotype</h3>
            <p>I’ll craft a distinctive and timeless logo that reflects your brand’s essence. Your decisions will help shape the final design, ensuring it leaves a lasting impression.</p>
          </div>
          <div className='each-service'>
            <h3>Brand / Visual Identity</h3>
            <p>Together, we’ll create a cohesive visual identity that captures your brand’s story, from logo to typography. I’ll guide the process to make sure it connects meaningfully with your audience.</p>
          </div>
          <div className='each-service'>
            <h3>Website Design / Build</h3>
            <p>Your website should be as unique as your brand. It should stand out and provide a seamless user experience, whether on Squarespace, Shopify, or WordPress.</p>
          </div>
          <div className='each-service'>
            <h3>Editorial Design </h3>
            <p>I’ll bring your content to life through refined editorial design, creating a custom look and feel that elevates the reader experience. Your choices will guide the final touches.</p>
          </div>
        </div>
      </section>

      <section className='home-process' id='process'>
        <div className='left'>
          <h2>The Process —</h2>
          <div className="each-step">
            <h3>01. Discovery</h3>
            <p>We start by understanding your brand, goals, and vision. This phase involves in-depth discussions and research to ensure we're aligned.</p>
          </div>
          <div className="each-step">
            <h3>02. Concept</h3>
            <p>Next, we develop initial ideas and concepts that reflect your brand's identity. You'll receive a clear vision of where we're headed.</p>
          </div>
          <div className="each-step">
            <h3>03. Design</h3>
            <p>Here, the magic happens. We bring the concepts to life, refining each detail to create a cohesive and visually compelling design.</p>
          </div>
          <div className="each-step">
            <h3>04. Delivery</h3>
            <p>Finally, we deliver the finished product, ensuring everything is polished and ready to elevate your brand.</p>
          </div>
        </div>
        <img src={Janel3} alt="perfume packaging" className='image-2' />
      </section>

      <section className='about-mission'>
        <div className='wrapper'>
          <p className='sub-title'>The Mission</p>
          <h2>Help you create an intentional, strategic, and hand-crafted brand that will give you confidence.</h2>
          <p>So you can be proud of a brand <br className='mobile'></br>that tells your story.</p>
          <p><NavLink to="/services/" activeStyle>Services</NavLink></p>
        </div>
        <img src={Janel4} alt="perfume packaging" className='image-2' />
      </section>

      <div className='wrapper'>
        <CTA />
      </div>

    </main >
  );
};

export default Home;