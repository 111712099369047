import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../Components/HeaderElements";
import Testimonials from "../Components/Testimonials.js";
import '../Style/Main.css';
import '../Style/Work.css';
import '../Style/Services.css';
import CTA from "../Components/CTA.js";


const Services = () => {
  return (
    <main id='main' className="service-page">
      <Helmet>
        <title>Janel Lucia | About</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="atf">
        <h1>Elevate your brand with custom design <br className="desktop"></br>solutions tailored to <span className='h-italics'>your vision.</span></h1>
      </section>

      <section className="service-wrap">
        <div className="service wrapper">
          <h2>Logo Design / Custom Logotype</h2>
          <p>A logo is the cornerstone of your brand’s identity, and I believe it should be as unique and memorable as your vision. As your design guide, I’ll craft a logo that is distinctive, thoughtful, and timeless, ensuring it leaves a lasting impression. While I lead the creative direction, your input will help shape the final design, resulting in something that truly resonates.</p>
        </div>
        <div className="service">
          <h2>Branding / Visual Identity</h2>
          <p>Your brand is a reflection of your story, and I design identities that bring that story to life. I’ll guide you in creating a cohesive visual identity, from logos and colour palettes to typography and visual style. Your input will refine the details, but I’ll ensure the overall design speaks to who you are and connects with your audience in a meaningful way.</p>
        </div>
        <div className="service">
          <h2>Website Design / Build</h2>
          <p>Your website is your digital home, and I’m here to make it as unique as your brand. I’ll craft an engaging, intuitive website that combines beauty with seamless functionality. Whether on Squarespace, Shopify, or WordPress (or something completely custom), your decisions will shape the final touches, ensuring it reflects your vision and goals.</p>
        </div>
        <div className="service">
          <h2>Editorial Design / Books / Magazines</h2>
          <p>I believe in the power of a well-crafted story, told through beautifully designed pages. With your project as my canvas, I’ll create a custom mood board, colour palette, and visual direction that brings your content to life, ensuring every element flows together to create a cohesive, captivating experience.</p>
        </div>
        <div className="service">
          <h2>Packaging Design</h2>
          <p>Packaging is the first touchpoint with your audience, and I design it to be as memorable as the product inside. From material selection to typography and finishes, every detail is thoughtfully considered. I will guide the process to create packaging that enhances your product and amplifies your brand’s story.</p>
        </div>
        <div className="service">
          <h2>Cards / Invites</h2>
          <p>Whether for personal celebrations or branded events, I design custom cards and invitations that make an unforgettable impact. From elegant wedding suites to refined business invitations, I’ll guide the creative process while your choices will bring the final details to life, ensuring your cards and invites leave a lasting impression.</p>
        </div>
      </section>

      <section className="testimonials">
        <h2 className="h-italics">kind words</h2>
        <Testimonials />
      </section>

      <section className='about-mission'>
        <div className='wrapper'>
          <p className='sub-title'>The Mission</p>
          <h2>Help you create an intentional, strategic, and hand-crafted brand that will give you confidence.</h2>
          <p>So you can be proud of a brand <br className='mobile'></br>that tells your story.</p>
          <p><NavLink to="/recent-work" activeStyle>Recent Work</NavLink></p>
        </div>
      </section>

      <div className='wrapper'>
        <CTA />
      </div>

    </main>
  );
};

export default Services;


