import React from "react";
import Accordion from "./Accordion";
import { NavLink } from "../../Components/HeaderElements";


function ShopFooter(props) {

  return (
    <>
      <div className='shop-footer'>
        <section className="whats-included">
          <div className="section-text">
            <h2>What’s included?</h2>
            <p>Each brand kit comes with a collection of high-end logos customized to fit your unique business. With a custom colour palette and font style guide so you don't have to guess how to style your brand. </p>
            <ul>
              <li>+ Primary Logo</li>
              <li>+ Secondary Logo(s)</li>
              <li>+ Brand Marks</li>
              <li>+ Custom Colour Palette</li>
              <li>+ Font Style Guide</li>
              <li>+ Brand Style Guide</li>
              <li>+ One Round of Revisions</li>
            </ul>
            <p>*each brand kit is only sold 5 times*</p>
          </div>
          <div className="image">
            <img src={props.image} alt="Semi Custom Brand layout" />
          </div>
        </section>
        <section className="how-it-works">
          <img src={props.image2} alt="Semi Custom Brand layout" className="image-2" />
          <img src={props.image3} alt="Semi Custom Brand layout" className="image-3" />
          <h2 className="h-italics">how it works...</h2>
          {/* <p>We'll change the name, secondary logo, tagline, colours, and the option to change the fonts! (Includes one revision).</p> */}
          <p className="title-text">Within a week you'll have confidence with <br className="desktop"></br>your new creative and cohesive brand</p>
          <div className="steps">
            <div className="step step-1">
              <h3>01</h3>
              <p className="strong">CHOOSE + PURCHASE</p>
              <p className="sub-text">Click on 'Buy Now' to secure your spot (I only work on 1 semi-custom brand per week).</p>
            </div>
            <div className="step step-2">
              <h3>02</h3>
              <p className="strong">BOOK + HOMEWORK</p>
              <p className="sub-text">After purchase, you'll receive an email with a link to your homework, brand workbook and the scheduler.</p>
            </div>
            <div className="step step-3">
              <h3>03</h3>
              <p className="strong">DESIGN DAY</p>
              <p className="sub-text">I'll check in prior (with any questions), but I'll start bright and early so I can get you a full review overview by the following week!</p>
            </div>
            <div className="step step-4">
              <h3>04</h3>
              <p className="strong">LAUNCH READY</p>
              <p className="sub-text">Once finalized, I'll save all of the final files for you, create your graphics and send them off to you so you can start planning your launch!</p>
            </div>
          </div>
        </section>
        <section className="shop-faq">
          <h2>Brand Kit FAQs</h2>
          <Accordion />
          <p className="faq-footer">If you have any questions, please don’t hesitate to reach out. We’re here to help! <br></br><NavLink to="mailto:hello@janellucia.com" target="new" activeStyle>hello@janellucia.com</NavLink></p>
        </section>
      </div>
    </>
  );
};

export default ShopFooter;