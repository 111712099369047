import '../../Style/Main.css';
import '../../Style/Shop-Page.css';
import 'react-slideshow-image/dist/styles.css'
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../../Components/HeaderElements";
import Reco from "../../Components/Shop/Reco";

import Clean1 from '../../Images/shop/clean-classic/clean-classic-1.png';
import Clean2 from '../../Images/shop/clean-classic/clean-classic-2.png';
import Clean3 from '../../Images/shop/clean-classic/clean-classic-3.png';
import Clean4 from '../../Images/shop/clean-classic/clean-classic-4.png';
import Clean5 from '../../Images/shop/clean-classic/clean-classic-5.png';
import Clean6 from '../../Images/shop/clean-classic/clean-classic-6.png';
import Clean7 from '../../Images/shop/clean-classic/clean-classic-7.png';
import Clean8 from '../../Images/shop/clean-classic/clean-classic-8.png';
import Clean9 from '../../Images/shop/clean-classic/clean-classic-9.png';
import Clean10 from '../../Images/shop/clean-classic/clean-classic-10.png';
import Clean11 from '../../Images/shop/clean-classic/clean-classic-11.png';
import Clean12 from '../../Images/shop/clean-classic/clean-classic-12.png';

import CCspread from '../../Images/shop/clean-classic/clean-classic-spread.png';
import CCfooter from '../../Images/shop/clean-classic/cc-footer.png';
import CCFooter2 from '../../Images/shop/clean-classic/clean-classic-3.png';
import CCFooter3 from '../../Images/shop/clean-classic/clean-classic-8.png';

import CC1 from '../../Images/shop/clean-classic/cc-1.png';
import CC2 from '../../Images/shop/clean-classic/cc-2.png';
import CC3 from '../../Images/shop/clean-classic/cc-3.png';
import CC4 from '../../Images/shop/clean-classic/cc-4.png';
import CC5 from '../../Images/shop/clean-classic/cc-5.png';
import CC6 from '../../Images/shop/clean-classic/cc-6.png';

import ShopPageIntro from "../../Components/Shop/ShopPageIntro"
import ShopFooter from "../../Components/Shop/ShopFooter"
import ImageSlider from "../../Components/Shop/ImageSlider"

// reco images
import Timeless1 from '../../Images/shop/classic-timeless/classic-timeless-1.png';
import Timeless2 from '../../Images/shop/classic-timeless/classic-timeless-2.png';
import Minimal1 from '../../Images/shop/minimal-sleek/minimal-sleek-1.png';
import Minimal2 from '../../Images/shop/minimal-sleek/minimal-sleek-2.png';
import Romantic1 from '../../Images/shop/classic-romantic/classic-romantic-6.png';
import Romantic2 from '../../Images/shop/classic-romantic/classic-romantic-2.png';

const images = [CC1, CC2, CC3, CC4, CC5, CC6];

const Clean = () => {
  return (
    <main id='main' className='shop-page shop-single'>
      <Helmet>
        <title>Janel Lucia | Clean & Classic Semi-Custom Brand</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="shop-atf">
        <div className="left">
          <ImageSlider images={images} />
        </div>
        <div className="right">
          <p>A Clean & Classic Semi-Custom Brand:</p>
          <h1>Scarlett Rose</h1>
          <p>Introducing our Clean & Classic Semi-Custom Brand Kit—a perfect blend of timeless elegance and modern simplicity. Designed for those who value both sophistication and functionality, this kit offers a refined look that effortlessly elevates your brand’s presence. With customizable elements that reflect your unique style, you can create a brand identity that’s both polished and enduring.</p>
          <NavLink to="https://www.paypal.com/ncp/payment/QAGNUJNN7QVNN" target="new" activeStyle>Get this Brand customized for you</NavLink>
        </div>
      </section>
      <ShopPageIntro bgimage={CCspread} />

      <section className='wrapper shop-images'>
        <NavLink to="https://www.paypal.com/ncp/payment/QAGNUJNN7QVNN" target="new" className="floating-button" activeStyle>Buy Now</NavLink>
        <div className='section-text'>
          <h2>What is a Semi-Custom brand?</h2>
          <p>With 10 years of experience, I understand what it takes to create a brand that not only looks great but also connects and attracts. A semi-custom brand is ideal for entrepreneurs who want to invest in something that aligns with their business goals and values, without the need to spend over $5K or wait 6+ weeks to bring it to life.</p>
          <p>The semi-custom option transforms a pre-designed brand "template" by customizing it with your name, colours, fonts, and inspiration, along with my unique lettering. It offers a personalized experience with a faster turnaround and a more budget-friendly price. Rest assured, I ensure each brand stands out and looks entirely unique!</p>
        </div>
        <div className='section-text-2'><p>We'll change the name, secondary logo, tagline, colours, and the option to change the fonts! (includes one revision)</p></div>
        <img src={Clean1} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Clean2} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Clean3} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Clean4} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Clean5} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Clean6} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Clean7} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Clean8} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Clean9} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Clean10} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Clean11} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
        <img src={Clean12} alt="Clean & Clasic Semi-Custom Brand Kit visual" />
      </section>
      <ShopFooter image={CCfooter} image2={CCFooter2} image3={CCFooter3} />
      <section className="client-call-to-action">
        <h2>Ready to Take Your <br className='mobile'></br><span className="h-italics">Brand to the Next Level?</span></h2>
        <p>Get this Brand customized for you.</p>
        <NavLink to="https://www.paypal.com/ncp/payment/QAGNUJNN7QVNN" target="new" className="call-to-action-btn" activeStyle>Buy Now</NavLink>
      </section>
      <Reco
        titleOne="Classic & Timeless"
        linkOne="/shop/classic-and-timeless"
        imageOne={Timeless1}
        imageTwo={Timeless2}
        titleTwo="Minimal & Sleek"
        linkTwo="/shop/minimal-and-sleek"
        imageThree={Minimal1}
        imageFour={Minimal2}
        titleThree="Classic & Romantic"
        linkThree="/shop/classic-and-romantic"
        imageFive={Romantic1}
        imageSix={Romantic2} />

    </main>
  );
};

export default Clean;