import '../../Style/Main.css';
import '../../Style/Projects.css';
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../../Components/HeaderElements";

import AnneSophie1 from '../../Images/work/anne-sophie/anne-sophie-1.jpg';
import AnneSophie2 from '../../Images/work/anne-sophie/anne-sophie-2.jpg';
import AnneSophie3 from '../../Images/work/anne-sophie/anne-sophie-3.jpg';
import AnneSophie4 from '../../Images/work/anne-sophie/anne-sophie-4.jpg';
import AnneSophie5 from '../../Images/work/anne-sophie/anne-sophie-5.jpg';
import AnneSophie6 from '../../Images/work/anne-sophie/anne-sophie-6.jpg';
import AnneSophie7 from '../../Images/work/anne-sophie/anne-sophie-7.jpg';
import AnneSophie8 from '../../Images/work/anne-sophie/anne-sophie-8.jpg';
import AnneSophie10 from '../../Images/work/anne-sophie/anne-sophie-10.jpg';
import AnneSophie11 from '../../Images/work/anne-sophie/anne-sophie-11.jpg';

import ProjectFooter from "../../Components/ProjectFooter"

const AnneSophie = () => {
  return (
    <main id='main' className='project-page wrapper'>
      <Helmet>
        <title>Janel Lucia | Anne Sophie Hout branding & website</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="project-atf">
        <div className="project-title">
          <p>Photography & Art Direction with a Personal Touch</p>
          <h1>Anne Sophie Hout</h1>
        </div>
        <div className="left">
          <p>Anne-Sophie Hout is a photographer and art director who specializes in digital and film content creation, as well as commercial photography. Her work blends creativity with technical precision, delivering visually captivating stories that resonate with her audience.</p>
          <p>We partnered with Anne-Sophie to design a brand identity that reflects her bold, artistic style. This included a strong typographic logo and building her website on Squarespace with custom sections. The site is fully accessible and offers both French and English options, ensuring an inclusive experience for all her users.</p>
          <NavLink to="https://www.annesophiehuot.com/" target="_blank" activeStyle>Anne Sopie Hout Webite</NavLink>
        </div>
        <div className="right">
          <h2>Services</h2>
          <ul>
            <li>Brand Identity</li>
            <li>Logo Design</li>
            <li>Squarespace site</li>
            <li>Web Accessibility</li>
            <li>French/English</li>
          </ul>
        </div>
      </section>

      <section className='three-across'>
        <img src={AnneSophie1} alt="Anne Sophie Hout website and  Branding visual" />
        <img src={AnneSophie2} alt="Anne Sophie Hout website and  Branding visual" />
        <img src={AnneSophie3} alt="Anne Sophie Hout website and  Branding visual" />
      </section>

      <section className='two-across'>
        <img src={AnneSophie4} alt="Anne Sophie Hout website and  Branding visual" />
        <img src={AnneSophie5} alt="Anne Sophie Hout website and  Branding visual" />
      </section>

      <section className='three-across'>
        <img src={AnneSophie6} alt="Anne Sophie Hout website and  Branding visual" />
        <img src={AnneSophie7} alt="Anne Sophie Hout website and  Branding visual" />
        <img src={AnneSophie8} alt="Anne Sophie Hout website and  Branding visual" />
      </section>

      <section className='two-across two-across-alt'>
        <img src={AnneSophie10} alt="Anne Sophie Hout website and  Branding visual" />
        <img src={AnneSophie11} alt="Anne Sophie Hout website and  Branding visual" />
      </section>

      <ProjectFooter />
    </main>
  );
};

export default AnneSophie;