import '../Style/Main.css';
import '../Style/About.css';
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../Components/HeaderElements";

import CTA from "../Components/CTA.js";

import Paralax from '../Images/jlds/paralax-bg.jpg';

import Janel1 from '../Images/jlds/janel-lucia-2.jpg';
import Janel2 from '../Images/jlds/janel-lucia-10.jpg';
import Janel3 from '../Images/jlds/janel-lucia-8.jpg';
import Janel4 from '../Images/jlds/janel-lucia-7.jpg';
import Janel5 from '../Images/jlds/janel-lucia-9.jpg';
import Janel6 from '../Images/jlds/janel-lucia-3.jpg';

import Millie1 from '../Images/jlds/millie.jpg';
import Millie2 from '../Images/jlds/millie-two.jpg';


const About = () => {
  return (
    <main id='main' className="about-wrapper">
      <Helmet>
        <title>Janel Lucia | About</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="about-atf">
        <div className='wrapper'>
          <div className="left">
            <h2>About</h2>
            <img src={Janel1} alt="Creating a mood board" />
          </div>
          <div className='right'>
            <h1>From Passion Project <br></br>— to Purpose</h1>
            <p>in 2016, while working in the hair & beauty industry, I hit ‘publish’ on my first branding and website project. The rush was immediate—I had found something that felt truly mine. Design wasn’t just a creative escape; it was a way to shape ideas and bring clarity to the world around me.</p>
            <p>Now, as a Designer and Website Developer in Toronto, I specialize in typography-driven branding, editorial design, and immersive digital experiences—blending thoughtfulness, elegance, and warmth into every project.</p>
            <p>From fashion and beauty to food & beverage, I’ve collaborated with brands like Bad Girls Collective, Holt Renfrew, Hudson’s Bay, and Livie Jewelry. What started as a passion project has become something much greater—a way to create, inspire, and connect through design.</p>
          </div>
        </div>
      </section>

      <section className='about-intro'>
        <div className='wrapper'>
          <img src={Janel2} alt="Janel Lucia Product Design" className='image-1' />
          <div className='text'>
            <p className='sub-title'>Janel Lucia Design Studio</p>
            <h2>A Design Studio for the Passionate, Ambitious, and Authentic Brand.</h2>
            <p>I’m here to elevate your brand with strategic, heart-driven design.</p>
            <p><NavLink to="/contact" activeStyle>Work Together</NavLink></p>
          </div>
          <img src={Janel3} alt="Janel Lucia Past Projects Compilation" className='image-2' />
        </div>
      </section>

      <section className='paralax desktop' style={{ backgroundImage: `url(${Paralax})` }}>
      </section>

      <section className='about-match'>
        <div className='wrapper'>
          <div className='left text'>
            <h3>We’re a Design Match <br className='desktop'></br>if you...</h3>
            <ol>
              <li>Have a deep passion for your brand and business.</li>
              <li>Value the impact of creative design.</li>
              <li>Appreciate art and handcrafted details.</li>
              <li>Value authenticity and want to grow in a way that feels true to you.</li>
              <li>Are committed to the design process.<span>(because the best results come from collaboration and trust)</span></li>
            </ol>
          </div>
          <div className="right">
            <img src={Janel4} alt="Janel Lucia" />
          </div>
          <img src={Janel5} alt="You are made of magic" className='image-2' />
        </div>
      </section>

      <section className='about-mission'>
        <div className='wrapper'>
          <p className='sub-title'>The Mission</p>
          <h2>Help you create an intentional, strategic, and hand-crafted brand that will give you confidence.</h2>
          <p>So you can be proud of a brand <br className='mobile'></br>that tells your story.</p>
          <p><NavLink to="/services/" activeStyle>Services</NavLink></p>
        </div>
      </section>

      <section className="about-janel">
        <div className='wrapper'>
          <div className='mobile'>
            <p className='sub-title'>Hi, I'm Janel</p>
            <h2>Your Brand Stylist, <br></br>Art Director & Design Expert</h2>
          </div>
          <div className="left">
            <img src={Janel6} alt="Janel Lucia" />
          </div>
          <div className='right'>
            <p className='sub-title desktop'>Hi, I'm Janel</p>
            <h2 className='desktop'>Your Brand Stylist, <br></br>Art Director & Design Expert</h2>
            <p>Design isn’t just a title I picked up from education or online courses; it’s been a part of me for as long as I can remember. From teaching myself typography and branding to diving into design projects with passion, this journey has been deeply personal.</p>
            <p>I focus on typography-driven branding, editorial design, and photographic art direction. My goal is to infuse both print and digital experiences with elegance and warmth, paying attention to the details that make a difference.</p>
            <p className='desktop'>I’ve had the pleasure of working with diverse industries like fashion, beauty, food & beverage, hospitality, luxury, and publishing. Collaborating with brands such as Bad Girls Collective, Holt Renfrew, Hudson's Bay, and Livie Jewelry has been both exciting and fulfilling.</p>
          </div>
          <div className='cta'>
            <p>Getting to see clients have confidence and love for their brand will forever fuel my soul</p>
            <NavLink to="/recent-work/" className="each-project" activeStyle>Recent Work</NavLink>
          </div>
        </div>
      </section>

      <section className="about-millie">
        <div className="left">
          <img src={Millie1} alt="My beautiful tabby cat Millie enjoying flowers" />
        </div>
        <div className='right'>
          <p className='sub-title'>Meet the Cutest</p>
          <p className='millie-title'>Little Tabby Cat </p>
          <div className='millie-image-text'>
            <img src={Millie2} alt="My beautiful tabby cat Millie resting" />
            <h2>Millie</h2>
          </div>
          <p>she's gentle yet playful.<br></br>she's shy yet affectionate.<br></br>she's small yet soft.</p>
        </div>
      </section>

      <div className='wrapper'>
        <CTA />
      </div>

    </main >
  );
};

export default About;