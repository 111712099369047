import '../../Style/Main.css';
import '../../Style/Shop.css';
import '../../Style/Shop-Page.css';
import 'react-slideshow-image/dist/styles.css'
import React from "react";
import { Helmet } from 'react-helmet';
import { NavLink } from "../../Components/HeaderElements";
import Reco from "../../Components/Shop/Reco";

import Minimal1 from '../../Images/shop/minimal-sleek/minimal-sleek-1.png';
import Minimal2 from '../../Images/shop/minimal-sleek/minimal-sleek-2.png';
import Minimal3 from '../../Images/shop/minimal-sleek/minimal-sleek-3.png';
import Minimal4 from '../../Images/shop/minimal-sleek/minimal-sleek-4.png';
import Minimal5 from '../../Images/shop/minimal-sleek/minimal-sleek-5.png';
import Minimal6 from '../../Images/shop/minimal-sleek/minimal-sleek-6.png';
import Minimal7 from '../../Images/shop/minimal-sleek/minimal-sleek-7.png';
import Minimal8 from '../../Images/shop/minimal-sleek/minimal-sleek-8.png';
import Minimal9 from '../../Images/shop/minimal-sleek/minimal-sleek-9.png';
import Minimal10 from '../../Images/shop/minimal-sleek/minimal-sleek-10.png';
import Minimal11 from '../../Images/shop/minimal-sleek/minimal-sleek-11.png';
import Minimal12 from '../../Images/shop/minimal-sleek/minimal-sleek-12.png';

import MSspread from '../../Images/shop/minimal-sleek/ms-spread.png';
import MSfooter from '../../Images/shop/minimal-sleek/ms-footer.png';
import MSfooter2 from '../../Images/shop/minimal-sleek/minimal-sleek-3.png';
import MSfooter3 from '../../Images/shop/minimal-sleek/minimal-sleek-9.png';

import MS1 from '../../Images/shop/minimal-sleek/ms-1.png';
import MS2 from '../../Images/shop/minimal-sleek/ms-2.png';
import MS3 from '../../Images/shop/minimal-sleek/ms-3.png';
import MS4 from '../../Images/shop/minimal-sleek/ms-4.png';

import ShopPageIntro from "../../Components/Shop/ShopPageIntro"
import ShopFooter from "../../Components/Shop/ShopFooter"
import ImageSlider from "../../Components/Shop/ImageSlider"

// reco images
import Clean1 from '../../Images/shop/clean-classic/clean-classic-1.png';
import Clean2 from '../../Images/shop/clean-classic/clean-classic-2.png';
import Romantic1 from '../../Images/shop/classic-romantic/classic-romantic-6.png';
import Romantic2 from '../../Images/shop/classic-romantic/classic-romantic-2.png';
import Timeless1 from '../../Images/shop/classic-timeless/classic-timeless-1.png';
import Timeless2 from '../../Images/shop/classic-timeless/classic-timeless-2.png';

const images = [MS1, MS2, MS3, MS4];

const Minimal = () => {
  return (
    <main id='main' className='shop-page shop-single'>
      <Helmet>
        <title>Janel Lucia | Minimal & Sleek Semi-Custom Brand</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="shop-atf shop-atf-alt">
        <div className="left desktop">
          <img src={MS1} alt="Minimal & Sleek Semi-Custom Brand Kit visual" />
          <img src={MS2} alt="Minimal & Sleek Semi-Custom Brand Kit visual" />
          <img src={MS3} alt="Minimal & Sleek Semi-Custom Brand Kit visual" />
          <img src={MS4} alt="Minimal & Sleek Semi-Custom Brand Kit visual" />
        </div>
        <div className="left mobile">
          <ImageSlider images={images} />
        </div>
        <div className="right">
          <p>A Minimal & Sleek Semi-Custom Brand:</p>
          <h1>Emma Hunter</h1>
          <p>Introducing our Minimal and Sleek Semi-Custom Brand Kit—crafted for those who appreciate the beauty of simplicity. This kit offers a streamlined design that’s both modern and refined, allowing your brand to make a statement without unnecessary clutter. With customizable features that emphasize clean lines and a sophisticated aesthetic, you can build a brand identity that's effortlessly chic and unmistakably yours.</p>
          <NavLink to="https://www.paypal.com/ncp/payment/KMSB9E8G56FJN" target="new" activeStyle>Get this Brand customized for you</NavLink>
        </div>
      </section>

      <ShopPageIntro bgimage={MSspread} />

      <section className='wrapper shop-images'>
        <NavLink to="https://www.paypal.com/ncp/payment/KMSB9E8G56FJN" target="new" className="floating-button" activeStyle>Buy Now</NavLink>
        <div className='section-text'>
          <h2>What is a Semi-Custom brand?</h2>
          <p>With 10 years of experience, I understand what it takes to create a brand that not only looks great but also connects and attracts. A semi-custom brand is ideal for entrepreneurs who want to invest in something that aligns with their business goals and values, without the need to spend over $5K or wait 6+ weeks to bring it to life.</p>
          <p>The semi-custom option transforms a pre-designed brand "template" by customizing it with your name, colours, fonts, and inspiration, along with my unique lettering. It offers a personalized experience with a faster turnaround and a more budget-friendly price. Rest assured, I ensure each brand stands out and looks entirely unique!</p>
        </div>
        <div className='section-text-2'>
          <p>We'll change the name, secondary logo, tagline, colours, and the option to change the fonts! (includes one revision)</p></div>
        <img src={Minimal1} alt="Minimal & Sleek Semi-Custom Brand Kit visual" />
        <img src={Minimal2} alt="Minimal & Sleek Semi-Custom Brand Kit visual" />
        <img src={Minimal3} alt="Minimal & Sleek Semi-Custom Brand Kit visual" />
        <img src={Minimal4} alt="Minimal & Sleek Semi-Custom Brand Kit visual" />
        <img src={Minimal6} alt="Minimal & Sleek Semi-Custom Brand Kit visual" />
        <img src={Minimal5} alt="Minimal & Sleek Semi-Custom Brand Kit visual" />
        <img src={Minimal7} alt="Minimal & Sleek Semi-Custom Brand Kit visual" />
        <img src={Minimal8} alt="Minimal & Sleek Semi-Custom Brand Kit visual" />
        <img src={Minimal9} alt="Minimal & Sleek Semi-Custom Brand Kit visual" />
        <img src={Minimal10} alt="Minimal & Sleek Semi-Custom Brand Kit visual" />
        <img src={Minimal12} alt="Minimal & Sleek Semi-Custom Brand Kit visual" />
        <img src={Minimal11} alt="Minimal & Sleek Semi-Custom Brand Kit visual" />
      </section>
      <ShopFooter image={MSfooter} image2={MSfooter2} image3={MSfooter3} />
      <section className="client-call-to-action">
        <h2>Ready to Take Your <br className='mobile'></br><span className="h-italics">Brand to the Next Level?</span></h2>
        <p>Get this Brand customized for you.</p>
        <NavLink to="https://www.paypal.com/ncp/payment/KMSB9E8G56FJN" target="new" className="call-to-action-btn" activeStyle>Buy Now</NavLink>
      </section>
      <Reco
        titleOne="Clean & Classic"
        linkOne="/shop/clean-and-classic"
        imageOne={Clean1}
        imageTwo={Clean2}
        titleTwo="Classic & Timeless"
        linkTwo="/shop/classic-and-timeless"
        imageThree={Timeless1}
        imageFour={Timeless2}
        titleThree="Classic & Romantic"
        linkThree="/shop/classic-and-romantic"
        imageFive={Romantic1}
        imageSix={Romantic2} />
    </main>
  );
};

export default Minimal;