import '../Style/Main.css';
import '../Style/Contact.css';
import React from "react";
import { Helmet } from 'react-helmet';


const Contact = () => {
  return (
    <main id='main' className="wrapper">
      <Helmet>
        <title>Janel Lucia | About</title>
        <meta name="description" content="Branding & Website services designed to Help you Grow your Business with Style & Confidence." />
      </Helmet>
      <section className="atf contact-atf">
        <h1>Let's create <br></br><span className="h-italics">something beautiful</span></h1>
        <p>Hey there, don't hesitate to say hello. <br></br>I'm always open to discuss new projects, <br className='mobile'></br>ideas and collaborations.</p>
      </section>

      <section className='contact-form'>

        <form action="https://api.staticforms.xyz/submit" method="post">
          <input type="hidden" name="accessKey" value="851a2b97-31f9-4d92-a8a1-18d4804fc519"></input>
          <div className='each-input'>
            <label for="Name">Name</label><br></br>
            <input type="text" name="$Name" placeholder="Name" required></input>
          </div>
          <div className='each-input'>
            <label for="Email">Email</label><br></br>
            <input type="email" name="$Email" placeholder='example@youremail.com' required></input>
          </div>
          <div className='each-textarea'>
            <label for="Design Needs">Design Needs</label><br></br>
            <textarea name="$Design Needs" placeholder='Branding, Website, Packaging, Templates, etc (be descriptive, the more the better!)' required></textarea>
          </div>
          <div className='each-textarea'>
            <label for="Tell us About You">Tell us About You</label><br></br>
            <textarea name="$Tell us About You" placeholder='What you do, who you are, your uniqueness, your design goals, etc' required></textarea>
          </div>

          <input type="hidden" name="replyTo" value="@"></input>
          <input type="hidden" name="redirectTo" value="https://janellucia.com/thank-you"></input>

          <input type="submit" value="Submit" className='submit' />
        </form>
      </section>

      <section className='contact-footer'>
        <h2>Thank you for considering working with me.</h2> <p>I look forward to the opportunity to bring your vision to life.</p>
      </section>

    </main >
  );
};

export default Contact;