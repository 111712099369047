import React from "react";
import '../../Style/Main.css';
import '../../Style/Shop.css';
import '../../Style/Shop-Page.css';



function ShopPageIntro(props) {
  return (
    <>
      <div>
        <section className='shop-item-intro'>
          <div className="wrapper">
            <h2>Ready to experience a <span className="h-italics">confidence boost?</span></h2>
            <p>If you are struggling to pair fonts, create a colour palette, or find it difficult to use brand elements consistently throughout your marketing, let me help you ;) This Semi-Custom Brand Kit will help you create a seamless brand experience that will attract your ideal client or customer.</p>
          </div>
        </section>
        <section className="paralax" style={{ backgroundImage: `url(${props.bgimage})` }} >

        </section>
      </div >
    </>
  );
};

export default ShopPageIntro;