import React from "react";
import '../Style/Header.css';
import { NavLink } from "./HeaderElements";


function CTA() {

  return (
    <>
      <section className="client-call-to-action client-call-to-action-2">
        <div className='wrapper'>
          <h2>Ready to take your <br className='mobile'></br>brand to the <span className="h-italics">next level?</span></h2>
          <p>Let’s create something beautiful together.</p>
          <NavLink to="/contact" className="each-project" activeStyle>Work Together</NavLink>
        </div>
      </section>
    </>
  );
};

export default CTA;